import * as Sentry from "@sentry/remix"
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { StrictMode, startTransition, useEffect } from "react"
import { hydrateRoot } from "react-dom/client"
import { APP_ENV_PREVIEW, APP_ENV_PROD } from "~/lib/const"

const isSentryEnabled =
  import.meta.env.VITE_APP_ENV === APP_ENV_PROD ||
  import.meta.env.VITE_APP_ENV === APP_ENV_PREVIEW

Sentry.init({
  dsn: "https://f936da2ef4f61b2155c5212d133a4165@o362985.ingest.us.sentry.io/4508135557300224",
  tracesSampleRate: 0.1,
  environment: import.meta.env.VITE_APP_ENV,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserSessionIntegration(),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  // 送信前処理
  beforeSend(event) {
    // プレビュー／本番環境以外はエラーを送信しない
    return isSentryEnabled ? event : null
  },
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
